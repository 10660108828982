<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">

    <vx-tour :steps="steps" v-if="!disableThemeTour && (windowWidth >= 1200 && mainLayoutType === 'vertical' && verticalNavMenuWidth == 'default')" />

    <the-customizer
      v-if                    = "!disableCustomizer"
      :footerType             = "footerType"
      :hideScrollToTop        = "hideScrollToTop"
      :navbarType             = "navbarType"
      :navbarColor            = "navbarColor"
      :routerTransition       = "routerTransition"
      @toggleHideScrollToTop  = "toggleHideScrollToTop"
      @updateFooter           = "updateFooter"
      @updateNavbar           = "updateNavbar"
      @updateNavbarColor      = "updateNavbarColor"
      @updateRouterTransition = "updateRouterTransition" />

    <v-nav-menu
      :navMenuItems = "navMenuItems"
      :logo         = "navMenuLogo"
      title         = "MooziMed"
      parent        = ".layout--main" />

    <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
      <div id="content-overlay" />

    <!-- Navbar -->
    <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
      <the-navbar-horizontal
        :navbarType= "navbarType"
        :logo         = "navMenuLogo"
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" />

      <div style="height: 62px" v-if="navbarType === 'static'"></div>

      <h-nav-menu
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]"
        :navMenuItems="navMenuItems" />
    </template>

    <template v-else>
      <the-navbar-vertical
        :navbarColor="navbarColor"
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" />
    </template>
    <!-- /Navbar -->

      <div class="content-wrapper">

        <div class="router-view">
          <div class="router-content">

            <transition :name="routerTransition">

              <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                <div
                  class="content-area__heading"
                  :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                  <h2 class="mb-1">{{ routeTitle }}</h2>
                </div>

                <!-- BREADCRUMB -->
                <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />

              </div>
            </transition>

            <div class="content-area__content">

              <back-to-top bottom="5%" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'" visibleoffset="500" v-if="!hideScrollToTop">
                <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
              </back-to-top>

              <transition :name="routerTransition" mode="out-in">
                <router-view @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
              </transition>
            </div>
          </div>
        </div>
      </div>
      <the-footer />
    </div>
  </div>
</template>


<script>
import BackToTop           from 'vue-backtotop'
import HNavMenu            from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue'
// import navMenuItems        from '@/layouts/components/vertical-nav-menu/navMenuItems.js'
import TheCustomizer       from '@/layouts/components/customizer/TheCustomizer.vue'
import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue'
import TheNavbarVertical   from '@/layouts/components/navbar/TheNavbarVertical.vue'
import TheFooter           from '@/layouts/components/TheFooter.vue'
import themeConfig         from '@/../themeConfig.js'
import VNavMenu            from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'

const VxTour = () => import('@/components/VxTour.vue')

export default {
  components: {
    BackToTop,
    HNavMenu,
    TheCustomizer,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
    VxTour
  },
  data () {
    return {
      disableCustomizer : themeConfig.disableCustomizer,
      disableThemeTour  : themeConfig.disableThemeTour,
      dynamicWatchers   : {},
      footerType        : themeConfig.footerType  || 'static',
      hideScrollToTop   : themeConfig.hideScrollToTop,
      isNavbarDark      : false,
      navbarColor       : themeConfig.navbarColor || '#fff',
      navbarType        : themeConfig.navbarType  || 'floating',
      navMenuLogo       : require('@/assets/images/logo/logo.png'),
      // navMenuItems,
      routerTransition  : themeConfig.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle,
      steps: [
        {
          target  : '#btnVNavMenuMinToggler',
          content : 'Toggle Collapse Sidebar.'
        },
        {
          target  : '.vx-navbar__starred-pages',
          content : 'Create your own bookmarks. You can also re-arrange them using drag & drop.'
        },
        {
          target  : '.i18n-locale',
          content : 'You can change language from here.'
        },
        {
          target  : '.navbar-fuzzy-search',
          content : 'Try fuzzy search to visit pages in flash.'
        },
        {
          target  : '.customizer-btn',
          content : 'Customize template based on your preference',
          params  : {
            placement: 'left'
          }
        },
        {
          target  : '.vs-button.buy-now',
          content : 'Buy this awesomeness at affordable price!',
          params  : {
            placement: 'top'
          }
        }
      ]
    }
  },
  watch: {
    '$route' () {
      this.routeTitle = this.$route.meta.pageTitle
    },
    isThemeDark (val) {
      const color = this.navbarColor === '#fff' && val ? '#10163a' : '#fff'
      this.updateNavbarColor(color)
    },
    '$store.state.mainLayoutType' (val) {
      this.setNavMenuVisibility(val)
      this.disableThemeTour = true
    }
  },
  computed: {
    navMenuItems () {
      const user_role = JSON.parse(localStorage.getItem('userInfo')).role.name
      // eslint-disable-next-line no-unused-vars
      //const user_name = JSON.parse(localStorage.getItem('userInfo')).username
      // eslint-disable-next-line no-unused-vars

      const dashboard = {
        url: '/dashboard',
        name: 'Dashboard',
        slug: 'dashboard',
        icon: 'HomeIcon',
        i18n: 'Dashboard'
      }
      const Utilisateurs = {
        url: '/Utilisateurs',
        name: 'Utilisateurs',
        icon: 'UsersIcon',
        slug: 'Utilisateurs',
        i18n: 'Utilisateurs'
      }

      /*  const Roles = {
        url: '/roles',
        name: 'roles',
        icon: 'UnlockIcon',
        slug: 'roles',
        i18n: 'roles'
      } */
      /* const cities = {
        url: '/cities',
        name: 'cities',
        slug: 'cities',
        icon: 'MapIcon',
        i18n: 'Villes'
      } */

      /*  const diseases = {
        url: '/diseases',
        name: 'diseases',
        slug: 'diseases',
        icon: 'ClipboardIcon',
        i18n: 'diseases'
      } */

      /*  const allergies = {
        url: '/allergies',
        name: 'allergies',
        slug: 'allergies',
        icon: 'PlusCircleIcon',
        i18n: 'allergies'
      } */

      /*  const operations = {
        url: '/operations',
        name: 'operations',
        slug: 'operations',
        icon: 'ActivityIcon',
        i18n: 'operations'
      }
 */
      /* const previousTreatments = {
        url: '/previousTreatments',
        name: 'previousTreatments',
        slug: 'previousTreatments',
        icon: 'MonitorIcon',
        i18n: 'previousTreatments'
      }
 */
      /*  const occupations = {
        url: '/occupations',
        name: 'occupations',
        slug: 'occupations',
        icon: 'LayersIcon',
        i18n: 'occupations'
      }
 */
      /*  const partnerTypes = {
        url: '/partnerTypes',
        name: 'partnerTypes',
        slug: 'partnerTypes',
        icon: 'AwardIcon',
        i18n: 'partnerTypes'
      }
 */
      /* const partners = {
        url: '/partners',
        name: 'partners',
        slug: 'partners',
        icon: 'UserCheckIcon',
        i18n: 'partners'
      } */
      
      /*  const appointments = {
        url: '/appointments',
        name: 'appointments',
        slug: 'appointments',
        icon: 'CalendarIcon',
        i18n: 'appointments'
      } */

      const calendar = {
        url: '/appointments_full',
        name: 'appointments_full',
        slug: 'appointments_full',
        icon: 'CalendarIcon',
        i18n: 'appointments'
      }

      /*    const appointments_list = {
        url: '/appointments_list',
        name: 'appointments',
        slug: 'appointments',
        icon: 'FileTextIcon',
        i18n: 'appointments'
      } */

      const patients = {
        url: '/patients',
        name: 'patients',
        slug: 'patients',
        icon: 'UserPlusIcon',
        i18n: 'patients'
      }

      /* const TypeActes = {
        url: '/TypeActes',
        name: 'TypeActes',
        slug: 'TypeActes',
        icon: 'ClipboardIcon',
        i18n: 'typeActes'
      } */

      /* const actes = {
        url: '/actes',
        name: 'actes',
        slug: 'actes',
        icon: 'BookIcon',
        i18n: 'actes'
      } */
      const treatments = {
        url: '/treatments',
        name: 'treatments',
        slug: 'treatments',
        icon: 'FolderIcon',
        i18n: 'treatments'
      }

      /*   const stockTypes = {
        url: '/stockTypes',
        name: 'stockTypes',
        slug: 'stockTypes',
        icon: 'AlignJustifyIcon',
        i18n: 'stockTypes'
      } */

      /* const stocks = {
        url: '/stocks',
        name: 'stocks',
        slug: 'stocks',
        icon: 'BarChartIcon',
        i18n: 'stocks'
      } */
  
      const app_list = {
        url: null,
        name: 'stocks_List',
        icon: 'BarChart2Icon',
        i18n: 'stocks_stocks',
        submenu: [
          {
            url: '/stocks',
            name: 'stocks',
            slug: 'stocks',
            i18n: 'stocks'
          },
          {
            url: '/materials',
            name: 'materials',
            slug: 'materials',
            i18n: 'materials'
          },
          {
            url: '/products',
            name: 'products',
            slug: 'products',
            i18n: 'products'
          },
          {
            url: '/enters',
            name: 'enters',
            slug: 'enters',
            i18n: 'enters'
          },
          {
            url: '/outs',
            name: 'outs',
            slug: 'outs',
            i18n: 'outs'
          }
        ]
      }
      /*  const app_list_comptable = {
        url: null,
        name: 'stocks_List',
        icon: 'BarChart2Icon',
        i18n: 'stocks_stocks',
        submenu: [
          {
            url: '/materials',
            name: 'materials',
            slug: 'materials',
            i18n: 'materials'
          },
          {
            url: '/products',
            name: 'products',
            slug: 'products',
            i18n: 'products'
          },
          {
            url: '/enters',
            name: 'enters',
            slug: 'enters',
            i18n: 'enters'
          },
          {
            url: '/outs',
            name: 'outs',
            slug: 'outs',
            i18n: 'outs'
          }
        ]
      } */
      const medical_care = {
        url: null,
        name: 'stocks_List',
        icon: 'BookIcon',
        i18n: 'soins_medical',
        submenu: [
          {
            url: '/actes',
            name: 'actes',
            slug: 'actes',
            i18n: 'actes'
          },
          {
            url: '/TypeActes',
            name: 'TypeActes',
            slug: 'TypeActes',
            i18n: 'typeActes'
          }
         
        ]
      }
      const parametre = {
        url: null,
        name: 'setting',
        icon: 'SettingsIcon',
        i18n: 'setting',
        submenu: [
          {
            url: '/occupations',
            name: 'occupations',
            slug: 'occupations',
            i18n: 'occupations'
          },
          {
            url: '/previousTreatments',
            name: 'previousTreatments',
            slug: 'previousTreatments',
            i18n: 'previousTreatments'
          },
          {
            url: '/operations',
            name: 'operations',
            slug: 'operations',
            i18n: 'operations'
          },
          {
            url: '/allergies',
            name: 'allergies',
            slug: 'allergies',
            i18n: 'allergies'
          },         
          {
            url: '/diseases',
            name: 'diseases',
            slug: 'diseases',
            i18n: 'diseases'
          },
          {
            url: '/cities',
            name: 'cities',
            slug: 'cities',
            i18n: 'Villes'
          },
          {
            url: '/roles',
            name: 'roles',
            slug: 'roles',
            i18n: 'roles'
          }
          

        ]
      }
     
      const sickLeaves = {
        url: '/sickLeaves',
        name: 'sickLeaves',
        slug: 'sickLeaves',
        icon: 'NavigationIcon',
        i18n: 'sickLeaves'
      }
      const sponsoring = {
        url: null,
        name: 'stocks_List',
        icon: 'AwardIcon',
        i18n: 'sponsoring',
        submenu: [
          {
            url: '/partnerTypes',
            name: 'partnerTypes',
            slug: 'partnerTypes',
            i18n: 'partnerTypes'
          },
          {
            url: '/partners',
            name: 'partners',
            slug: 'partners',
            i18n: 'partners_p'
          }
          /* {
            url: '/assurances',
            name: 'partners',
            slug: 'partners',
            i18n: 'partners'
          },
          {
            url: '/pharmacies',
            name: 'pharmacies',
            slug: 'pharmacies',
            i18n: 'pharmacies'
          } */
        ]
      }
      const medical_doc = {
        url: null,
        name: 'stocks_List',
        icon: 'FolderIcon',
        i18n: 'medical_doc',
        submenu: [
          {
            url: '/patients',
            name: 'patients',
            slug: 'patients',
            i18n: 'patients'
          },
          {url: '/treatments',
            name: 'treatments',
            slug: 'treatments',
            i18n: 'treatments'
          }, 
          {
            url: '/prescriptions',
            name: 'prescriptions',
            slug: 'prescriptions',
            i18n: 'prescriptions'
          },  
          {
            url: '/sickLeaves',
            name: 'sickLeaves',
            slug: 'sickLeaves',
            i18n: 'sickLeaves'
          }
         
        ]
      }
      const prescriptions = {
        url: '/prescriptions',
        name: 'prescriptions',
        slug: 'prescriptions',
        icon: 'FilePlusIcon',
        i18n: 'prescriptions'
      }

      const bills = {
        url: '/bills',
        name: 'bills',
        slug: 'bills',
        i18n: 'bills',
        icon: 'ListIcon',
        submenu: [
         
          {
            url: '/bills_paid',
            name: 'bills_paid',
            slug: 'bills_paid',
            i18n: 'bills_paid'
          },
          {
            url: '/bills_not_paid',
            name: 'bills_not_paid',
            slug: 'bills_not_paid',
            i18n: 'bills_not_paid'
          },
          {
            url: '/recouvrement',
            name: 'recouvrement',
            slug: 'recouvrement',
            i18n: 'recouvrements'
          },
          {
            url: '/bills_to_settle',
            name: 'bills_to_settle',
            slug: 'bills_to_settle',
            i18n: 'bills_to_settle'
          }
          
        ]
      }
      let role = []
      const role_secretary = [
        calendar,
        patients
        
      ]
      const role_genereralist = [
        dashboard,
        calendar,
        prescriptions,
        treatments,
        sickLeaves,
        patients
      ]
      const role_accountant = [
        dashboard,
        bills,
        //stocks,
        app_list
      ]

      //const role_accountant = [calendar]
      
      const role_admin = [
        dashboard,
        calendar,
        bills,
        medical_doc,
        //prescriptions,
        //sickLeaves,
        //patients,
        //appointments_list,
        //appointments,
        //treatments,
        medical_care,
        //actes,
        //TypeActes,
        sponsoring,
        //partners,
        //partnerTypes,
        app_list,
        //stocks,
        parametre,
        //stockTypes,
        //occupations,
        //previousTreatments,
        //operations,
        //allergies,
        //diseases,
        //cities,
        //Roles,
        Utilisateurs
      ]

      const role_adminslave = [
        dashboard,
        calendar,
        bills,
        medical_doc,
        //prescriptions,
        //sickLeaves,
        //patients,
        //appointments_list,
        //appointments,
        //treatments,
        medical_care,
        //actes,
        //TypeActes,
        sponsoring,
        //partners,
        //partnerTypes,
        app_list,
        //stocks,
        parametre,
        //stockTypes,
        //occupations,
        //previousTreatments,
        //operations,
        //allergies,
        //diseases,
        //cities,
        //Roles,
        Utilisateurs
      ]

      const role_dentist = [
        dashboard,
        calendar,
        //appointments, 
        treatments,
        prescriptions,
        sickLeaves,
        patients

      ]
      if (user_role === 'accountant') {
        role = role_accountant
      }
      if (user_role === 'secretary') {
        role = role_secretary
      }


      if (user_role === 'admin') {
        role = role_admin
      }

      if (user_role === 'dentist') {
        role = role_dentist
      }
      if (user_role === 'generalist') {
        role = role_genereralist
      }

      if (user_role === 'slaveadmin') {
        role = role_adminslave
      }
      return role
    },
    bodyOverlay () { return this.$store.state.bodyOverlay },
    contentAreaClass () {
      if (this.mainLayoutType === 'vertical') {
        if      (this.verticalNavMenuWidth === 'default') return 'content-area-reduced'
        else if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg'
        else return 'content-area-full'
      } else return 'content-area-full'
    },
    footerClasses () {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static'
      }
    },
    isAppPage () {
      return this.$route.meta.no_scroll
    },
    isThemeDark ()     { return this.$store.state.theme === 'dark' },
    layoutTypeClass () { return `main-${this.mainLayoutType}`      },
    mainLayoutType ()  { return this.$store.state.mainLayoutType   },
    navbarClasses ()   {
      return {
        'navbar-hidden'   : this.navbarType === 'hidden',
        'navbar-sticky'   : this.navbarType === 'sticky',
        'navbar-static'   : this.navbarType === 'static',
        'navbar-floating' : this.navbarType === 'floating'
      }
    },
    verticalNavMenuWidth () { return this.$store.state.verticalNavMenuWidth },
    windowWidth ()          { return this.$store.state.windowWidth }
  },
  methods: {
    changeRouteTitle (title) {
      this.routeTitle = title
    },
    updateNavbar (val) {
      if (val === 'static') this.updateNavbarColor(this.isThemeDark ? '#10163a' : '#fff')
      this.navbarType = val
    },
    updateNavbarColor (val) {
      this.navbarColor = val
      if (val === '#fff') this.isNavbarDark = false
      else this.isNavbarDark = true
    },
    updateFooter (val) {
      this.footerType = val
    },
    updateRouterTransition (val) {
      this.routerTransition = val
    },
    setNavMenuVisibility (layoutType) {
      if ((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === 'vertical' && this.windowWidth < 1200)) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
      } else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      }
    },
    toggleHideScrollToTop (val) {
      this.hideScrollToTop = val
    }
  },
  created () {
    const color = this.navbarColor === '#fff' && this.isThemeDark ? '#10163a' : this.navbarColor
    this.updateNavbarColor(color)
    this.setNavMenuVisibility(this.$store.state.mainLayoutType)

    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch('$store.state.windowWidth', (val) => {
      if (val < 1200) {
        this.disableThemeTour = true
        this.dynamicWatchers.windowWidth()
      }
    })

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch('$store.state.verticalNavMenuWidth', () => {
      this.disableThemeTour = true
      this.dynamicWatchers.verticalNavMenuWidth()
    })

    this.dynamicWatchers.rtl = this.$watch('$vs.rtl', () => {
      this.disableThemeTour = true
      this.dynamicWatchers.rtl()
    })
  },
  beforeDestroy () {
    Object.keys(this.dynamicWatchers).map(i => {
      this.dynamicWatchers[i]()
      delete this.dynamicWatchers[i]
    })
  }
}

</script>

